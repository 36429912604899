import React, { useEffect, useState } from 'react'
import ClubBanner from '../components/ClubBanner/ClubBanner'
import ClubSubscription from '../components/ClubSubscription/ClubSubscription'
import Footer from '../components/Footer/Footer'
import KitPreview from '../components/KitPreview/KitPreview'
import Newsletter from '../components/Newsletter/Newsletter'
import NextKit from '../components/NextKit/NextKit'
import PreviousKits from '../components/PreviousKits/PreviousKits'
import TAGTopics from '../components/TAGTopics/TAGTopics'
import { ThemeProvider } from 'styled-components'
import VideoModal from '../components/VideoModal/VideoModal'
import { clubBannerIneditosData } from '../components/ClubBanner/ClubBannerData'
import { clubIneditosData } from '../components/ClubSubscription/ClubSubscriptionData'
import { graphql } from 'gatsby'
import { ineditosTopicsData } from '../components/TAGTopics/TAGTopicsData'
import { kitPreviewIneditosData } from '../components/KitPreview/KitPreviewData'
import { nextIneditosData } from '../components/NextKit/NextKitData'
import useChatbot from '../helpers/useChatbot'
import HowItWorks from '@site/components/organisms/HowItWorks'
import CookiesFab from '@site/components/organisms/CookiesFab'
import Experience from '@site/components/organisms/Experience'
import CommonHead from '@shared/meta/CommonHead'
import useSubscriptionPlans from '@site/hooks/useSubscriptionPlans'

export function Head() {
  return (
    <CommonHead
      title="Títulos inéditos | TAG Experiências Literárias"
      description="Best-sellers e grandes apostas contendo o melhor da literatura contemporânea que você não vai conseguir parar de ler. Confira essa experiência! "
    />
  )
}

const ineditosTheme = {
  'bannerBackgroundColor': '#f0e5cc',
  'clubMainColor': '#ff9e16',
  'clubSecondaryColor': '#ea821b',
  'howItWorksSubtitleLineHeight': '1.8',
  'howItWorksTitleMaxWidth': '250px',
  'largeSelector': true,
  'planSelectorBackgroundColor': '#ff9e16',
  'planSelectorUncheckedButtonColor': '#96a0c0',
  'topicsBackgroundColor': '#ffa639',
  'topicsNumberColor': '#ffc97e',
}

const Ineditos = ( { data } ) => {
  const [ videoModalOpen, setVideoModalOpen ] = useState( false )
  const [ isPageRendered, setIsPageRendered ] = useState( false )
  const { 'allStrapiIneditos': { edges } } = data
  const [ strapiData ] = edges
  const kitPreviewImages =
  {
    'desktopImage': strapiData.node.kitPreviewImage.desktopImage?.localFile?.publicURL,
    'mobileImage': strapiData.node.kitPreviewImage.mobileImage?.localFile?.childImageSharp?.gatsbyImageData.images
  }
  const bannerSubtitle = strapiData.node.banner.subtitle
  const bannerActiveClock = strapiData.node.banner.activeClock
  const kitPreviewData = { ...kitPreviewImages, ...kitPreviewIneditosData }
  const nextKitData = { ...strapiData.node.nextKit, ...nextIneditosData }
  const bannerData = { ...clubBannerIneditosData, 'subtitle': bannerSubtitle, 'activeClock': bannerActiveClock }

  const { 'allStrapiGifts': { nodes } } = data
  const ineditosGift = nodes[ 0 ].ineditos

  useChatbot()
  const subscriptionPlans = useSubscriptionPlans()

  useEffect( () => {
    setIsPageRendered( true )
  }, [] )

  return (
    <>
      {( videoModalOpen &&
        <VideoModal
          onClose={ () => {
            setVideoModalOpen( false )
          } }
          source={ `${process.env.GATSBY_ASSETS_URL}/video_ineditos.mp4` }
        /> )}
      <ThemeProvider theme={ ineditosTheme }>
        <ClubBanner
          playButtonOnClick={ () => {
            setVideoModalOpen( true )
          } }
          { ...bannerData }
        />
        <HowItWorks
          page="ineditos"
          subscriptionPlans={ subscriptionPlans }
        />
        <KitPreview { ...kitPreviewData } />
        <PreviousKits club="ineditos" />
        <NextKit { ...nextKitData } />
        <TAGTopics { ...ineditosTopicsData } />
        <ClubSubscription { ...clubIneditosData( subscriptionPlans, ineditosGift ) } />
        { isPageRendered && <Experience /> }
        <Newsletter />
        <Footer />
        <CookiesFab />
      </ThemeProvider>
    </>
  )
}

export default Ineditos

export const query = graphql`{
  allStrapiIneditos(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        banner {
          subtitle
          activeClock
        }
        howItWorks {
          column {
            id
            subtitle
            title
          }
          title
        }
        kitPreviewImage {
          desktopImage {
            localFile {
              publicURL
            }
          }
          mobileImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        nextKit {
          title
          description
          items {
            id
            subtitle
            icon {
              url
              alternativeText
            }
          }
          buttonText
          buttonLink
        }
      }
    }
  }
  allStrapiGifts(limit: 1, sort: {order: DESC, fields: strapiId}) {
    nodes {
      ineditos {
        title
        text
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData (
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        zoomImage {
          localFile {
            childImageSharp {
              gatsbyImageData (
                width: 1500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        description
      }
    }
  }
}

`
